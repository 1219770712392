import request from "@/utils/request";

export function login(username, password) {
  const data = new FormData();
  data.append("username", username);
  data.append("password", password);
  return request.post("login", data);
}
export function loginEbb(username, password) {
  const data = new FormData();
  data.append("username", username);
  data.append("password", password);
  return request.post("ebb/login", data);
}
export function loginJbb(username, password) {
  const data = new FormData();
  data.append("username", username);
  data.append("password", password);
  return request.post("jbb/login", data);
}
export function info() {
  return request.get("jbb/user/info");
}
export function infoSso() {
  return request.get("user/infoSso");
}
//新增员工
export function add(form) {
  return request.post("/user/add", form);
}
//注册
export function register(form) {
  return request.post("/user/register", form);
}

export function changePassword(origin, password) {
  const data = new FormData();
  data.append("password", origin);
  data.append("newPassword", password);
  return request.post("user/changePassword", data);
}
export function pagination(criteria) {
  return request.get("/user", {
    params: {
      ...criteria
    }
  });
}
export function details(id) {
  return request.get(`jbb/member/${id}`);
}
//编辑员工
export function edit(json) {
  return request.post(`/user/${json.id}`, json);
}
//删除
export function deleteItem(id) {
  return request.post(`/user/${id}/delete`);
}

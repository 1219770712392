import { pagination, detail, del } from "@/api/order";
const actions = {
  pagination(_, form) {
    return pagination(form);
  },
  detail(_, id) {
    return detail(id);
  },
  del(_, id) {
    return del(id);
  }
};
export default {
  namespaced: true,
  actions
};

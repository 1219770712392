export default [
  {
    name: "personal-center",
    path: "/personalCenter",
    redirect: "product",
    meta: {
      title: "会员中心",
      layout: "MainLayout",
      // 需要登录才能访问此路由及其子路由
      auth: true
    },
    component: () => import("@/views/personalCenter/centerIndex.vue"),
    children: [
      {
        path: "/ebb/products",
        name: "ebb-products",
        component: () =>
          import("@/views/personalCenter/modules/ebb/products.vue"),
        meta: {
          title: "产品投保",
          layout: "MainLayout"
        }
      },
      {
        path: "/jbb/products",
        name: "jbb-products",
        component: () =>
          import("@/views/personalCenter/modules/jbb/products.vue"),
        meta: {
          title: "产品投保",
          layout: "MainLayout"
        }
      },
      {
        path: "/change/password",
        name: "change-password",
        meta: {
          title: "修改密码",
          layout: "MainLayout"
        },
        component: () =>
          import("@/views/personalCenter/modules/user/changePassword.vue")
      },
      {
        path: "/basic/info",
        name: "basic-info",
        meta: {
          title: "个人资料",
          layout: "MainLayout"
        },
        component: () =>
          import("@/views/personalCenter/modules/user/basicInfo.vue")
      },
      {
        path: "/account/profilePicture",
        name: "profile-picture",
        meta: {
          title: "替换首页图片",
          layout: "MainLayout"
        },
        component: () =>
          import("@/views/personalCenter/modules/profilePicture/index.vue")
      }
    ]
  }
];

<template>
  <el-footer class="layout-footer" height="70px">
    <div style="flex: 1"></div>
    <!--    <div class="item">-->
    <!--      <span class="title">关于{{ appName }}：</span-->
    <!--      ><span>{{ appName }}介绍</span><span>联系客服</span-->
    <!--      ><span>欢迎致电(400-928-8399)</span>-->
    <!--    </div>-->
    <div class="item">
      <!-- Copyright&copy; 2021 {{ appName }}网 版权所有 -->
      <!-- <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
        >冀ICP备17016143号</a
      > -->
    </div>
  </el-footer>
</template>

<script>
export default {
  data() {
    return {
      appName: process.env.VUE_APP_TITLE
    };
  }
};
</script>

<style lang="scss" scoped>
.layout-footer {
  padding: 20px;
  display: flex;
  flex-direction: column;

  .item {
    text-align: center;
    line-height: 30px;
    color: $--color-text-secondary;
    font-size: 14px;

    & > span {
      display: inline-block;
      padding: 0 7.5px;
    }

    .title {
      padding: 0;
    }
  }
}
</style>

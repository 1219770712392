<template>
  <!-- <header style="top: 61px;left: 0px;"> -->
  <header style="left: 0px;" id="header">
    <div id="header-body" class="section">
      <div class="container" style="height:100%;">
        <!-- logo -->
        <div class="logo-box">
          <!-- <img class="logo" src="https://templatedemo.website.com/img/demo_ws_logo.png"></img> -->
        </div>
        <!-- 标题 -->
        <div class="title-container">
          <ul class="title-box">
            <li>
              <el-button
                type="text"
                @click="toContent('div1', 1)"
                :class="active == 1 ? 'activeClass' : ''"
                ><span>关于我们 </span></el-button
              >
            </li>
            <!-- <li>
              <el-button
                type="text"
                @click="toContent('div2', 2)"
                :class="active == 2 ? 'activeClass' : ''"
                id="div1Button"
                >关于我们</el-button
              >
            </li> -->
            <li>
              <el-button type="text" @click="toContent('div3')"
                >业务介绍</el-button
              >
            </li>
            <li>
              <el-button type="text" @click="toContent('div4')"
                >联系我们</el-button
              >
            </li>
            <li>
              <el-button type="text" @click="onWeb()">保险购买</el-button>
            </li>
            <li style="width:200px ;text-align: right;">
              <el-button
                type="text"
                style="font-size: 12px;"
                @click="onOperation()"
                >后台管理</el-button
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>

export default {
  data() {
    return {
      // icons: {
      //   background: `url('${require("@/assets/icons.png")}') -2px -3px no-repeat`
      // }
      active: 1,
      showIntroduction: true
    };
  },

  methods: {
    toContent(divId, index) {
      this.active = index;
      const offsetTop = document.getElementById(divId).offsetTop; //元素到顶部的距离
      const options = {
        left: 0,
        top: offsetTop - 80,
        behavior: "smooth"
      };

      window.scrollTo(options);
    },
    onWeb() {
      this.active = "";
      window.open("https://v2.jinbaobao.com.cn/", "_blank");
    },
    onOperation() {
      this.active = "";
      window.open("https://v2.jinbaobao.com.cn/center/", "_blank");
    }
  }
};
</script>
<style scoped>
header {
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 50%);
  position: fixed;
  width: 100%;
  min-width: 1100px;
  top: 0;
  z-index: 90000;
  height: 80px;
  background-color: white;
  opacity: 0.9;
}

.section > .container {
  /* width: 1100px !important; */
  margin: 0 auto;
  display: flex;
  line-height: 80px;
  flex-direction: row;
}
.title-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
li {
  /* float: right; */
  margin: 0 20px;
  /* width: 130px; */
}
li button {
  font-family: STHeiti;
  font-size: 16px;
  color: #000000ab;
}

/*li button :active{
  color: rgb(21 33 93 / 80%);
} */
.el-button:focus,
.el-button:hover {
  color: rgb(21 33 93 / 80%);
  border-bottom: #68af57 solid 4px;
  font-weight: bold;
}
.activeClass {
  color: rgb(21 33 93 / 80%);
  border-bottom:#68af57 solid 4px;
  font-weight: bold;
}

.title-container {
  float: right;
  margin-left: 320px;
  /* position: absolute;
  right: 180px; */
}
.logo-box {
  width: 600px;
  line-height: 80px;
}
.logo {
  margin: 0 auto;
  display: inline-block;
  vertical-align: center;
}
</style>

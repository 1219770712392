const state = {
  collapsed: false,
  isMobile: false
};

const mutations = {
  toggleCollapse(state) {
    state.collapsed = !state.collapsed;
  },
  setDevice(state, isMobile) {
    state.isMobile = isMobile;
  }
};

const actions = {
  toggleCollapse({ commit }) {
    commit("toggleCollapse");
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

import axios from "axios";
import { Message } from "element-ui";
import { getToken } from "@/utils/token";

// create an axios instance
const service = axios.create({
  // baseURL: "", // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
  headers: {
    common: {
      Authorization: getToken()
    }
  }
});

// request interceptor
service.interceptors.request.use(
  config => {
    const url = String(config.url);
    const regex = /\/?(.*?)\/(.*)/;
    // eslint-disable-next-line no-unused-vars
    const [_, api, uri] = regex.exec(url) || [null, null, null];
    if (api === "ebb") {
      config.baseURL = process.env.VUE_APP_BASE_EBB_API;
      config.url = uri;
    } else if (api === "jbb") {
      config.baseURL = process.env.VUE_APP_BASE_JBB_API;
      config.url = uri;
    }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    const { code, msg } = response.data;

    if (code !== 2000) {
      Message({
        message: msg || "接口请求返回错误",
        type: "error",
        duration: 5 * 1000
      });
      return Promise.reject(new Error(msg || "接口请求返回错误"));
    } else {
      return response;
    }
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;

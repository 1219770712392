import router from "./router";
import { pauthCheck } from "./permission";
import environment from "./environment";
import { setToken } from "@/utils/token";
// 开放式路由配置
import openRouters from "@/router/modules/open";
// 个人中心路由配置
import personalCenterRouters from "@/router/modules/personalCenter";
// 开放式路由和个人中心路由有些共用的页面，为了最小化改动，使用拦截器在适当的时机将前往这些页面的导航进行动态替换
// 收集共用的页面路由

const collect = routes => {
  return routes.map(({ name, path, children }) => {
    return children
      ? [
          {
            name,
            path
          },
          collect(children)
        ].flat()
      : {
          name,
          path
        };
  });
};

const map = [openRouters, personalCenterRouters]
  .map(item => {
    return collect(item);
  })
  .flat(2)
  .reduce((p, { name, path }) => {
    let _name = name.startsWith("open-") ? name.split("open-")[1] : name;
    p[_name] ? p[_name].push(path) : (p[_name] = [path]);
    return p;
  }, {});

// 共用的路由
const commonRoutes = Object.entries(map).flatMap(([k, v]) => {
  return v.length > 1 ? k : [];
});

router.beforeEach((to, from, next) => {
  // if (to.name === "product" && to.query.Authorization !== undefined) {
  if (to.query.Authorization !== undefined) {
    setToken(to.query.Authorization);
  }
  // 是否存在对应的开放路由
  const isOpen = commonRoutes.includes(to.name);
  // url是否含有mid，只有含mid的才能访问开放路由
  // let isMid = false;
  // if (to.query.mid) {
  //   isMid = true;
  // } else if (from.query.mid) {
  //   isMid = true;
  //   to.query.mid = from.query.mid;
  // }
  const { matched } = to;
  document.title =
    environment.projectName +
    " | " +
    matched
      .map(({ meta }) => meta?.title)
      .filter(s => s)
      .join(" - ");
  let needAuth = false;
  [...matched].reverse().some(({ meta }) => {
    const { auth } = meta || {};
    if (auth === undefined || auth === null) {
      // 该层路由没有明确指定是否需要登录认证，则寻找上一层路由的配置
      return false;
    }
    needAuth = auth;
    return true;
  });
  if (!isOpen && needAuth) {
    // console.log("重定向", to.fullPath);
    pauthCheck()
      .then(next)
      .catch(() => next("/?redirect=" + to.fullPath));
    next();
  } else {
    // if (isOpen && isMid) {
    //   next({
    //     name: "open-" + to.name,
    //     query: to.query,
    //     params: to.params,
    //     meta: to.meta
    //   });
    // } else {
    next();
    // }
  }
});

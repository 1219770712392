<template>
  <div class="layout">
    <el-container style="height: 100%" direction="vertical">
      <!--      页头-->
      <in-header>111</in-header>
      <!--      插槽-->
      <slot></slot>
      <!--      页脚-->
      <page-footer />
    </el-container>
  </div>
</template>

<script>
import InHeader from "./header/InHeader";
import PageFooter from "./footer/PageFooter";
export default {
  name: "HomeLayout",
  components: {
    InHeader,
    PageFooter
  }
};
</script>

<style scoped></style>

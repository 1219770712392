export default [
  {
    path: "/mobile/login",
    name: "mobile-login",
    meta: {
      title: "微信|手机版登录"
    },
    component: () => import("@/views/mobile/login.vue")
  },
  {
    path: "/mobile/register",
    name: "mobile-register",
    meta: {
      title: "手机版注册"
    },
    component: () => import("@/views/mobile/register.vue")
  },
  {
    path: "/mobile/index",
    name: "mobile-index",
    meta: {
      title: "手机版个人中心",
      position: "inner"
    },
    component: () => import("@/views/mobile/index.vue")
  },
  {
    path: "/mobile/product/list",
    name: "mobile-product-list",
    meta: {
      title: "产品投保",
      position: "inner"
    },
    component: () => import("@/views/mobile/product/list.vue")
  },
  {
    path: "/mobile/order/list",
    name: "mobile-order-list",
    meta: {
      title: "订单管理",
      position: "inner"
    },
    component: () => import("@/views/mobile/order/list.vue")
  },
  {
    path: "/mobile/order/detail/:id",
    name: "mobile-order-detail",
    meta: {
      title: "订单详情"
    },
    component: () => import("@/views/mobile/order/detail.vue")
  },
  {
    path: "/mobile/order/listRenewal",
    name: "mobile-order-listRenewal",
    meta: {
      title: "续保订单",
      position: "inner"
    },
    component: () => import("@/views/mobile/order/listRenewal.vue")
  },
  {
    path: "/mobile/other/index",
    name: "mobile-other-index",
    meta: {
      title: "其他",
      position: "inner"
    },
    component: () => import("@/views/mobile/other/index.vue")
  },
  {
    path: "/mobile/report/list",
    name: "mobile-report-list",
    meta: {
      title: "理赔案件"
    },
    component: () => import("@/views/mobile/report/list.vue")
  },
  {
    path: "/mobile/report/deail/:id",
    name: "mobile-report-detail",
    meta: {
      title: "理赔案件详情"
    },
    component: () => import("@/views/mobile/report/detail.vue")
  }
];

import request from "@/utils/request";

export function pagination(criteria) {
  return request.get("jbb/order/pagination", {
    params: {
      ...criteria
    }
  });
}
export function detail(orderId) {
  return request.get(`jbb/order/${orderId}`);
}

export function del(id) {
  return request.post(`jbb/order/${id}/delete`);
}

// import router from "./router";
import store from "./store/index";
// 很奇怪，在这个文件里router引入后为undefined

export function authCheck() {
  return store.getters["user/isUserExisted"];
}

export function pauthCheck() {
  return new Promise((resolve, reject) => {
    if (store.getters["user/isUserExisted"]) {
      resolve();
    }
    // 现在是面向b端，因此可以全局获取用户信息而不用考虑用户可以匿名访问
    store.dispatch("user/info").then(
      () => {
        store.getters["user/isUserExisted"] ? resolve() : reject();
      },
      e => reject(e)
    );
  });
}

function auth() {
  const vue = this;
  if (store.getters["user/isUserExisted"]) {
    return true;
  } else {
    vue.$router.push({
      name: "login",
      query: {
        redirect: vue.$route.fullPath
      }
    });
    return false;
  }
}

function pauth() {
  if (auth()) {
    return Promise.resolve();
  }
}

export default {
  install: Vue => {
    Vue.prototype.$auth = auth;
    Vue.prototype.$pauth = pauth;
    Vue.prototype.$pauthCheck = pauthCheck;
    Vue.prototype.$authCheck = authCheck;
  }
};

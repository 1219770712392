import { list, edit } from "@/api/profilePicture";

const actions = {
  list(_, form) {
    return list(form);
  },
  edit(_, form) {
    return edit(form);
  }
};
export default {
  namespaced: true,
  actions
};

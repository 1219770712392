import Vue from "vue";
import Vuex from "vuex";
import camelcase from "camelcase";

const req = require.context("./modules", false, /\.js$/);
const requireAll = requireContext =>
  requireContext.keys().reduce((p, n) => {
    if (!n.includes("index.js")) {
      // 去掉开头的./和结尾的.js
      p[camelcase(n.substring(2, n.length - 3))] = requireContext(n).default;
    }
    return p;
  }, {});

const modules = requireAll(req);

Vue.use(Vuex);

export default new Vuex.Store({
  modules
});

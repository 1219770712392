/**
 * 通用的配置，可以不设置
 */
const common = {
  projectName: "亿保云科技",
  // 项目logo
  logo: require("@/assets/logo.png"),
  // 文件服务器地址
  downloadHost: "http://download.ebaobei168.com",
  //jbb 文件服务器地址
  downloadHostJbb: "http://download.jinbaobao.com.cn",
  //ebb跳转地址
  // toEbbHost: "http://localhost:8103",
  toEbbHost: "http://www.ebaobei168.com",
  //jbb跳转地址
  toJbbHost: "http://www.jinbaobao.com.cn"
};

module.exports = {
  ...common
};

const TOKEN_KEY = "Authorization";
const TOKEN_KEY_EBB = "AuthorizationEbb";
const TOKEN_KEY_JBB = "AuthorizationJbb";
export function getToken() {
  return localStorage.getItem(TOKEN_KEY) || "";
}

export function setToken(token) {
  return localStorage.setItem(TOKEN_KEY, token);
}
export function setTokenEbb(token) {
  return localStorage.setItem(TOKEN_KEY_EBB, token);
}
export function setTokenJbb(token) {
  return localStorage.setItem(TOKEN_KEY_JBB, token);
}
export function removeToken() {
  return localStorage.removeItem(TOKEN_KEY);
}
export function removeTokenEbb() {
  return localStorage.removeItem(TOKEN_KEY_EBB);
}
export function removeTokenJbb() {
  return localStorage.removeItem(TOKEN_KEY_JBB);
}

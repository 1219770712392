import { commonRoutes, authorizedRoutes } from "@/router/index";

function hasPermission({ roles }, route) {
  return hasRolePermission(roles, route);
}

function hasRolePermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role.type));
  }
  return true;
}

// eslint-disable-next-line no-unused-vars
function hasCompanyPermission(company, route) {
  if (!company) return true; // 用户没有所属公司就不处理公司权限，否则公司id为0的用户无法看见菜单
  if (route.meta && route.meta.companies) {
    return route.meta.companies.includes(company);
  }
  return true;
}

function filterAuthorizedRoutes(routes, roles) {
  const res = [];
  routes.forEach(route => {
    const tmp = { ...route };
    if (hasPermission({ roles }, tmp)) {
      if (tmp.children) {
        tmp.children = filterAuthorizedRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}

/**
 * 合并路由配置
 * @param {Array} target
 * @param {Array} source
 * @returns {Array}
 */
function mergeRoutes(target, source) {
  const routesMap = {};
  target.forEach(route => (routesMap[route.path] = route));
  source.forEach(route => {
    if (routesMap[route.path]) {
      const children = routesMap[route.path].children || [];
      routesMap[route.path].children = children.concat(route.children);
    } else {
      routesMap[route.path] = route;
    }
  });
  return Object.values(target);
}

const state = {
  routes: [...commonRoutes],
  addRoutes: []
};

const getters = {
  routes: s => s.routes
};

const mutations = {
  setRoutes(state, routes) {
    state.addRoutes = routes;
    state.routes = mergeRoutes(commonRoutes, routes);
  }
};

const actions = {
  generateRoutes({ commit }, { roles }) {
    return new Promise(resolve => {
      const routes = filterAuthorizedRoutes(authorizedRoutes, roles);
      commit("setRoutes", routes);
      resolve(routes);
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

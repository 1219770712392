<template>
  <div id="app">
    <!-- <main-layout v-if="layout === 'MainLayout'">
      <router-view />
    </main-layout>
    <in-layout v-else-if="layout === 'InLayout'">
      <router-view v-if="isRouterAlive" />
    </in-layout>
    <register-layout v-else-if="layout === 'RegisterLayout'">
      <router-view />
    </register-layout>
    <router-view v-else-if="isRouterAlive" /> -->
    <van-tabbar v-model="active" route v-if="position === 'inner'">
      <van-tabbar-item replace to="/mobile/product/list" icon="goods-collect"
        >产品投保</van-tabbar-item
      >
      <van-tabbar-item replace to="/mobile/order/list" icon="orders-o"
        >订单管理</van-tabbar-item
      >
      <van-tabbar-item replace to="/mobile/order/listRenewal" icon="orders-o"
        >续保管理</van-tabbar-item
      >
      <van-tabbar-item replace icon="more-o" to="/mobile/other/index">
        其他</van-tabbar-item
      >
    </van-tabbar>

    <router-view v-if="isRouterAlive && layout !== 'InLayout'"></router-view>
    <!-- web登录首页 -->
    <in-layout v-if="layout === 'InLayout'">
      <router-view v-if="isRouterAlive" />
    </in-layout>
  </div>
</template>

<script>
// import MainLayout from "@/layouts/MainLayoutV2";
import InLayout from "@/layouts/InLayout";
// import RegisterLayout from "@/layouts/RegisterLayout";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload
    };
  },
  components: {
    // MainLayout,
    InLayout
    // RegisterLayout
  },
  data() {
    return {
      lTime: new Date().getTime(), //最后一次点击时间
      ctTime: new Date().getTime(),
      tOut: 60 * 1000, // 超时时间1min
      isRouterAlive: true,
      active: null
    };
  },
  mounted() {
    // window.setInterval(this.tTime, 1000); //每一秒调用一次
  },
  created() {
    this.$store.dispatch("user/info");
  },
  computed: {
    layout() {
      return this.$route.meta?.layout || "default";
    },
    layoutProps() {
      return this.$route.meta?.layoutProps || {};
    },
    position() {
      return this.$route.meta?.position || "default";
    }
  },
  methods: {
    // clicked() {
    //   this.lTime = new Date().getTime(); //当界面被点击更新点击时间
    // },
    // tTime() {
    //   this.cTime = new Date().getTime();
    //   let flag = this.cTime - this.lTime > this.tOut;
    //   if (flag === true) {
    //     if (localStorage.getItem("Authorization-ebaobei") !== null) {
    //       //处于登录的状态,退出登录
    //       this.$store.dispatch("user/logout").then(
    //         //弹出提醒框
    //         this.$confirm("登录超时，是否重新登录？", "提示", {
    //           confirmButtonText: "确定",
    //           cancelButtonText: "取消",
    //           type: "warning"
    //         })
    //           .then(() => {
    //             this.$router.push("/");
    //           })
    //           .catch(() => {
    //             console.log("取消");
    //           })
    //       );
    //     }
    //   }
    // }
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>
<style>
html,
body {
  scroll-behavior: smooth;
}
</style>

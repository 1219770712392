/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import personalCenter from "./modules/personalCenter";
import mobile from "./modules/mobile"
Vue.use(VueRouter);

// 所有角色通用的路由
export const commonRoutes = [{
    path: "/",
    name: "login",
    meta: {
      title: "登录",
      layout: "InLayout"
    },
    component: () => import("@/views/login/content.vue"),
    hidden: true
  }, {
    path: "/registeration",
    name: "registration",
    meta: {
      title: "注册",
      layout: "RegisterLayout"
    },
    component: () => import("@/views/login/registration.vue")
  }

];

export const authorizedRoutes = [];

const createRouter = () =>
  new VueRouter({
    // mode: "history",
    mode: "hash",
    base: process.env.BASE_URL,
    routes: [...commonRoutes, ...personalCenter, ...mobile]
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;

import { pagination, detail } from "@/api/report";
const actions = {
  pagination(_, criteria) {
    return pagination(criteria);
  },
  detail(_, id) {
    return detail(id);
  }
};
export default {
  namespaced: true,
  actions
};

// 自定义权限指令
import Vue from "vue";
import store from "./store";

// eg. <input v-role:disabled="[admin, user]"></input>
Vue.directive("role", {
  bind: (el, binding) => {
    const roles = store.getters["user/roles"];
    const { arg, value } = binding;
    if (!value) {
      return;
    }
    const type = arg || "hidden";
    let access = [];
    if (value instanceof String) {
      access.push(value);
    } else if (value instanceof Array) {
      access = [...value];
    }
    if (hasPermission(roles, access)) {
      return;
    }
    if (type === "hidden") {
      el.setAttribute("style", "display:none");
    } else if (type === "disabled") {
      el.setAttribute("disabled", "disabled");
    }
  }
});

Vue.directive("company", {
  bind: (el, binding) => {
    const user = store.getters["user/user"];
    const companyType = user.companyType;
    const { arg, value } = binding;
    if (!value) {
      return;
    }
    const type = arg || "hidden";
    let access = [];
    if (value instanceof String) {
      access.push(value);
    } else if (value instanceof Array) {
      access = [...value];
    }
    if (hasCompanyPermission(companyType, access)) {
      return;
    }
    if (type === "hidden") {
      el.setAttribute("style", "display:none");
    } else if (type === "disabled") {
      el.setAttribute("disabled", "disabled");
    }
  }
});

function hasCompanyPermission(type, access) {
  return access.includes(type);
}

function hasPermission(roles, access) {
  return roles.some(role => access.includes(role.type));
}

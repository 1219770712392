import {
  paginationEbb,
  allRiskEbb,
  listCompanyEbb,
  listNewsEbb,
  paginationJbb,
  allRiskJbb,
  listCompanyJbb,
  listNewsJbb,
  shareLink
} from "@/api/product";
const actions = {
  paginationEbb(_, criteria) {
    return paginationEbb(criteria);
  },
  paginationJbb(_, criteria) {
    return paginationJbb(criteria);
  },
  allRiskEbb(_, criteria) {
    return allRiskEbb(criteria);
  },
  allRiskJbb(_, criteria) {
    return allRiskJbb(criteria);
  },
  listCompanyEbb(_, criteria) {
    return listCompanyEbb(criteria);
  },
  listCompanyJbb(_, criteria) {
    return listCompanyJbb(criteria);
  },
  listNewsEbb(_, criteria) {
    return listNewsEbb(criteria);
  },
  listNewsJbb(_, criteria) {
    return listNewsJbb(criteria);
  },
  createShareLink(_, id) {
    return shareLink(id);
  }
};
export default {
  namespaced: true,
  actions
};

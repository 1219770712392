import request from "@/utils/request";
export function paginationEbb(criteria) {
  return request.get("ebb/product/personalCenter/pagination", {
    params: {
      ...criteria
    }
  });
}
export function paginationJbb(criteria) {
  return request.get("jbb/product/personalCenter/pagination", {
    params: {
      ...criteria
    }
  });
}
export function allRiskEbb() {
  return request.get("ebb/risk/all");
}
export function allRiskJbb() {
  return request.get("jbb/risk/all");
}
export function listCompanyEbb() {
  return request.get("ebb/insurance/company");
}
export function listCompanyJbb() {
  return request.get("jbb/insurance/company");
}
export function listNewsEbb({ type }) {
  return request.get("ebb/disclosure/list", {
    params: {
      type
    }
  });
}
export function listNewsJbb({ type }) {
  return request.get("jbb/disclosure/list", {
    params: {
      type
    }
  });
}
export function shareLink(productId) {
  return request.post(`jbb/product/shareLink?productId=${productId}`);
}
